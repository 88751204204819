import React from 'react';

import { Alert } from '@grafana/ui';

import { useSettings } from '../../hooks';
import { DashboardLink } from '../DashboardLink';

type Props = {
  children?: React.ReactNode;
};
export const SelfServeWrapper = ({ children }: Props) => {
  const { data: settings, isError: isSettingsError, error: settingsError } = useSettings();

  if (isSettingsError) {
    return (
      <Alert title={'Something went wrong'} severity={'error'}>
        {settingsError.statusText}
      </Alert>
    );
  }

  if (settings) {
    if (settings.selfServeDisabled) {
      return (
        <Alert title={'Cloud Logs Export disabled'} severity={'warning'}>
          The Cloud Logs Export feature is not yet enabled for self-service use in your region. If you have a Cloud Pro
          or Cloud Advanced account, you can file a{' '}
          <a className={'external-link'} href={'https://grafana.com/profile/org/tickets/new'}>
            support ticket
          </a>{' '}
          to have Grafana Labs configure Cloud Logs Export for you. <DashboardLink />
        </Alert>
      );
    }
  }
  return <>{children}</>;
};
