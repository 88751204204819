import React, { useState } from 'react';
import { css } from '@emotion/css';
import { find as _find } from 'lodash';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, ButtonVariant, ConfirmModal, useStyles2 } from '@grafana/ui';

import { useUpdateConfigMutation } from '../../../hooks';
import { TenantConfig } from '../../../types/logs-export-config';
import { AWS_REGION_OPTIONS, CLOUD_PROVIDER_TRANSLATION } from '../../../utils/constants';

const getStyles = (theme: GrafanaTheme2) => ({
  details: css`
    display: grid;
    grid-template-columns: auto 1fr; // shrink columns to fit content
    column-gap: ${theme.spacing(2)};

    & dt {
      color: ${theme.colors.text.secondary};
    }
  `,
});

type Props = {
  data: TenantConfig;
};
export const Summary = ({ data }: Props) => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const { mutateAsync } = useUpdateConfigMutation();
  const styles = useStyles2(getStyles);

  const {
    buttonVariant,
    buttonText,
    modalTitle,
    modalBody,
    modalConfirmButtonVariant,
  }: {
    buttonVariant: ButtonVariant;
    buttonText: string;
    modalTitle: string;
    modalBody: string;
    modalConfirmButtonVariant: ButtonVariant;
  } = data?.enabled
    ? {
        buttonVariant: 'secondary',
        buttonText: 'Disable log syncing',
        modalTitle: 'Disable log syncing',
        modalBody: 'By disabling log syncing your logs will no longer be synced, but no data will be deleted.',
        modalConfirmButtonVariant: 'destructive',
      }
    : {
        buttonVariant: 'primary',
        buttonText: 'Enable log syncing',
        modalTitle: 'Enable log syncing',
        modalBody: 'By enabling log syncing your logs will synced to the bucket you have configured.',
        modalConfirmButtonVariant: 'primary',
      };

  return (
    <>
      <dl className={styles.details}>
        <dt>Cloud provider</dt>
        <dd>{CLOUD_PROVIDER_TRANSLATION[data.provider]}</dd>
        {data.provider === 'aws' && awsBucketDetails(data)}
        {data.provider === 'azure' && azureBucketDetails(data)}
        {data.provider === 'gcp' && gcpBucketDetails(data)}
      </dl>
      <Button
        variant={buttonVariant}
        onClick={() => {
          setIsConfirmModalOpen(!isConfirmModalOpen);
        }}
      >
        {buttonText}
      </Button>
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        title={modalTitle}
        body={modalBody}
        confirmText={'Ok'}
        confirmButtonVariant={modalConfirmButtonVariant}
        onConfirm={async () => {
          try {
            await mutateAsync({
              ...data,
              enabled: !data.enabled,
            });
            setIsConfirmModalOpen(false);
          } catch (e) {
            console.error(e);
          }
        }}
        onDismiss={() => {
          setIsConfirmModalOpen(false);
        }}
      />
    </>
  );
};

const gcpBucketDetails = (data: TenantConfig) => {
  return (
    <>
      <dt>Bucket name</dt>
      <dd>{data.gcp?.bucket}</dd>
    </>
  );
};

const awsBucketDetails = (data: TenantConfig) => {
  return (
    <>
      <dt>Bucket name</dt>
      <dd>{data.aws?.bucket}</dd>
      <dt>Bucket region</dt>
      <dd>{_find(AWS_REGION_OPTIONS, (value) => value.value === data.aws?.region)?.label}</dd>
    </>
  );
};

const azureBucketDetails = (data: TenantConfig) => {
  return (
    <>
      <dt>Container name</dt>
      <dd>{data.azure?.container}</dd>
      <dt>Storage account name</dt>
      <dd>{data.azure?.storage_account}</dd>
      <dt>Azure tenant id</dt>
      <dd>{data.azure?.tenant_id}</dd>
    </>
  );
};
