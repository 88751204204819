import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

export const getCommonStyles = (theme: GrafanaTheme2) => {
  return {
    list: css`
      list-style-position: inside;
      padding-top: ${theme.spacing(2)};
      & li {
        padding-bottom: ${theme.spacing(2)};
      }
    `,
  };
};
