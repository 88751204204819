import React from 'react';
import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

const getStyles = (theme: GrafanaTheme2) => {
  return {
    wrapper: css`
      margin-bottom: ${theme.spacing(2)};
    `,
  };
};
export const DocumentationLink = () => {
  const styles = useStyles2(getStyles);
  return (
    <div className={styles.wrapper}>
      To learn more, please see our{' '}
      <a className={'external-link'} href={'https://grafana.com/docs/grafana-cloud/data-configuration/logs/export/'}>
        documentation
      </a>
      .
    </div>
  );
};
