import React from 'react';
import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { LoadingPlaceholder, useStyles2 } from '@grafana/ui';

import { useLoadingSpinner } from '../../hooks';

const getStyles = (theme: GrafanaTheme2) => {
  return {
    modalBackdrop: css`
      position: absolute;
      z-index: ${theme.zIndex.modalBackdrop};
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: ${theme.components.overlay.background};
      backdrop-filter: blur(1px);
      display: flex;
      align-items: center;
      justify-content: center;
    `,
  };
};

export const LoadingSpinnerOverlay = () => {
  const { shouldShowLoadingSpinner } = useLoadingSpinner();

  const styles = useStyles2(getStyles);

  if (!shouldShowLoadingSpinner) {
    return null;
  }

  return (
    <div className={styles.modalBackdrop} data-testid={'loading-spinner-overlay'}>
      <LoadingPlaceholder text={'Loading...'} />
    </div>
  );
};
