import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import copy from 'copy-to-clipboard';
import { differenceInSeconds } from 'date-fns';

import { FetchError } from '@grafana/runtime';

import { get, post, put } from '../api';
import { LogExportContext } from '../context/log-export-context';
import { Settings } from '../types/app-config';
import {
  CellDetails,
  CloudProvider,
  TenantConfig,
  TestConnectivityInit,
  TestConnectivityStatus,
} from '../types/logs-export-config';
import { errorAlert, successAlert } from '../utils/alert';
import { UNEXPECTED_ERROR } from '../utils/constants';

const QUERY_KEYS = {
  cellDetails: 'cell-details',
  save: 'save',
  settings: 'settings',
  status: 'status',
  tenantConfigData: 'tenant-config-data',
  testConnectivity: 'test-connectivity',
  update: 'update',
} as const;

const TEST_TIMEOUT_SECONDS = 30;

export const useAPI = () => {
  const { api } = useContext(LogExportContext);
  if (typeof api === 'undefined') {
    throw new Error('useApi must be used within a LogExportContextProvider');
  }
  return api;
};

export const useLoadingSpinner = () => {
  const { shouldShowLoadingSpinner, setQueryKeyLoading } = useContext(LogExportContext);
  return { shouldShowLoadingSpinner, setQueryKeyLoading };
};

export default function useCopyToClipboard(text: string, duration = 2500): [boolean, () => void] {
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (isCopied) {
      const id = setTimeout(() => {
        setIsCopied(false);
      }, duration);
      return () => {
        clearTimeout(id);
      };
    }
    return () => void 0;
  }, [isCopied, duration]);

  return [
    isCopied,
    useCallback(() => {
      const didCopy = copy(text, { debug: true });
      setIsCopied(didCopy);
    }, [setIsCopied, text]),
  ];
}

export const useCellDetails = () => {
  const api = useAPI();
  const { setQueryKeyLoading } = useLoadingSpinner();

  const query = useQuery<CellDetails, FetchError>(
    [QUERY_KEYS.cellDetails],
    () => {
      setQueryKeyLoading([QUERY_KEYS.cellDetails], true);
      return get<CellDetails>(api.v1.cellDetails);
    },
    {
      onSettled: async () => {
        setQueryKeyLoading([QUERY_KEYS.cellDetails], false);
      },
    }
  );

  const renderServiceAccountDetails = (cloudProvider: CloudProvider) => {
    if (!query.data) {
      return '';
    }
    switch (cloudProvider) {
      case 'aws':
        return query.data.aws.arn;
      case 'azure':
        return query.data.azure.application_id;
      case 'gcp':
        return query.data.gcp.service_account;
      default:
        return '';
    }
  };

  return { ...query, renderServiceAccountDetails };
};

export const useSettings = () => {
  const api = useAPI();
  const { setQueryKeyLoading } = useLoadingSpinner();

  return useQuery<Settings, FetchError>(
    [QUERY_KEYS.settings],
    () => {
      setQueryKeyLoading([QUERY_KEYS.settings], true);
      return get<Settings>(api.v1.settings);
    },
    {
      onSettled: async () => {
        setQueryKeyLoading([QUERY_KEYS.settings], false);
      },
    }
  );
};

export const useTenantConfig = () => {
  const api = useAPI();
  const { setQueryKeyLoading } = useLoadingSpinner();

  return useQuery<TenantConfig, FetchError>(
    [QUERY_KEYS.tenantConfigData],
    () => {
      setQueryKeyLoading([QUERY_KEYS.tenantConfigData], true);
      return get<TenantConfig>(api.v1.tenantConfigs);
    },
    {
      onSettled: async () => {
        setQueryKeyLoading([QUERY_KEYS.tenantConfigData], false);
      },
    }
  );
};

export const useUpdateConfigMutation = () => {
  const api = useAPI();
  const queryClient = useQueryClient();
  const { setQueryKeyLoading } = useLoadingSpinner();

  return useMutation<unknown, FetchError, TenantConfig>(
    async (configData: TenantConfig) => {
      await put<TenantConfig, unknown>(api.v1.tenantConfigs, configData);
    },
    {
      onMutate: async () => {
        setQueryKeyLoading([QUERY_KEYS.tenantConfigData, QUERY_KEYS.update], true);
      },
      onSettled: async () => {
        setQueryKeyLoading([QUERY_KEYS.tenantConfigData, QUERY_KEYS.update], false);
      },
      // invalidates the config query data to refetch when update happens.
      onSuccess: async () => {
        successAlert('Successfully updated configuration.');
        await queryClient.invalidateQueries([QUERY_KEYS.tenantConfigData]);
      },
      onError: async (error) => errorAlert(error.data.message, error.statusText || 'Error'),
    }
  );
};

export const useSaveConfigMutation = () => {
  const api = useAPI();
  const queryClient = useQueryClient();
  const { setQueryKeyLoading } = useLoadingSpinner();

  return useMutation<unknown, FetchError, TenantConfig>(
    async (configData: TenantConfig) => {
      await post(api.v1.tenantConfigs, configData);
    },
    {
      // invalidates the config query data to refetch when update happens.
      onSuccess: async () => {
        successAlert('Successfully saved configuration.');
        await queryClient.invalidateQueries([QUERY_KEYS.tenantConfigData]);
      },
      onError: async (error) => errorAlert(error.data.message || UNEXPECTED_ERROR, error.statusText || 'Error'),
      onMutate: async () => {
        setQueryKeyLoading([QUERY_KEYS.tenantConfigData, QUERY_KEYS.save], true);
      },
      onSettled: async () => {
        setQueryKeyLoading([QUERY_KEYS.tenantConfigData, QUERY_KEYS.save], false);
      },
    }
  );
};

export const useInitTestConnectivity = () => {
  const api = useAPI();
  const { setQueryKeyLoading } = useLoadingSpinner();

  return useMutation<TestConnectivityInit, FetchError, Omit<TenantConfig, 'enabled'>>(
    async (configData: Omit<TenantConfig, 'enabled'>) =>
      await post<TestConnectivityInit>(api.v1.testConnectivity, configData),
    {
      onError: async (error) => {
        errorAlert(error.data.message || UNEXPECTED_ERROR, error.statusText);
      },
      onMutate: async () => {
        setQueryKeyLoading([QUERY_KEYS.testConnectivity], true);
      },
      onSettled: async () => {
        setQueryKeyLoading([QUERY_KEYS.testConnectivity], false);
      },
    }
  );
};

export const useTestConnectivityStatus = (request_id: string | undefined) => {
  const api = useAPI();
  const { setQueryKeyLoading } = useLoadingSpinner();
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setHasError(false);
  }, [request_id]);

  const start = useMemo(() => new Date(), [request_id]); // eslint-disable-line react-hooks/exhaustive-deps

  return useQuery<TestConnectivityStatus, FetchError>(
    [QUERY_KEYS.testConnectivity, QUERY_KEYS.status],
    () => {
      setQueryKeyLoading([QUERY_KEYS.testConnectivity, QUERY_KEYS.status], true);
      return get<TestConnectivityStatus>(api.v1.testConnectivity, { request_id });
    },
    {
      enabled: !!request_id && !hasError,
      onSettled: async (data) => {
        if (data?.status !== 'in-progress') {
          setQueryKeyLoading([QUERY_KEYS.testConnectivity, QUERY_KEYS.status], false);
        }
      },
      onError: () => {
        setHasError(true);
      },
      refetchInterval: (data) => {
        return !data || data.status === 'in-progress' ? 200 : false;
      },
      select: (data) => {
        const now = new Date();
        if (data.status === 'in-progress' && differenceInSeconds(now, start) >= TEST_TIMEOUT_SECONDS) {
          return {
            msg: `Connection timed out. Please try again.`,
            status: 'failed',
          };
        }
        return data;
      },
    }
  );
};
