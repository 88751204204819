import React, { useMemo, useState } from 'react';
import { css, cx } from '@emotion/css';

import { GrafanaTheme2, SelectableValue } from '@grafana/data';
import { RadioButtonGroup, useStyles2 } from '@grafana/ui';

import { useCellDetails } from '../../../hooks';
import { getCommonStyles } from '../../../utils/common-styles';
import { CodeSnippet } from '../../CodeSnippet';

const getStyles = (theme: GrafanaTheme2) => {
  const commonStyles = getCommonStyles(theme);
  return {
    list: cx(commonStyles.list),
    codeBlockWrapper: css`
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: ${theme.spacing(2)};
    `,
  };
};

const getPolicyCode = (arn: string, versioned: boolean) => `{
    "Statement": [
        {
            "Effect": "Allow",
            "Principal": {
                "AWS": "${arn}"
            },
            "Action": "s3:ListBucket",
            "Resource": "arn:aws:s3:::{{ BUCKET_NAME }}"
        },
        {
            "Effect": "Allow",
            "Principal": {
                "AWS": "${arn}"
            },
            "Action": [
                "s3:GetObject",
                "s3:GetObjectAttributes",
                "s3:PutObject"${
                  versioned
                    ? `,
                "s3:GetObjectVersion",
                "s3:GetObjectVersionAttributes"`
                    : ''
                }
            ],
            "Resource": "arn:aws:s3:::{{ BUCKET_NAME }}/*"
        }
    ]
}
`;

const bucketTypeOptions: SelectableValue[] = [
  { label: 'Versioned bucket', value: 'versioned' },
  { label: 'Non-versioned bucket', value: 'non-versioned' },
];

export const InstructionsAws = () => {
  const [bucketType, setBucketType] = useState<'versioned' | 'non-versioned'>('versioned');
  const { data, isLoading, renderServiceAccountDetails } = useCellDetails();

  const policyStatement = useMemo(() => {
    return {
      versioned: getPolicyCode(data?.aws.arn || '{{ GRAFANA_PRINCIPAL_ARN }}', true),
      nonVersioned: getPolicyCode(data?.aws.arn || '{{ GRAFANA_PRINCIPAL_ARN }}', false),
    };
  }, [data]);

  const styles = useStyles2(getStyles);

  if (isLoading) {
    return <span data-testid={'loading-placeholder'} />;
  }

  return (
    <>
      <h3>Amazon S3 (AWS) - Instructions</h3>
      <ol className={styles.list}>
        <li>Create an S3 bucket to export your logs to.</li>
        <li>
          Allow Cloud Logs Export access to your bucket by creating and attaching a bucket policy statement.
          <div className={styles.codeBlockWrapper}>
            <RadioButtonGroup
              options={bucketTypeOptions}
              value={bucketType}
              onChange={(value) => setBucketType(value)}
            />
            {bucketType === 'versioned' && <CodeSnippet code={policyStatement.versioned} />}
            {bucketType === 'non-versioned' && <CodeSnippet code={policyStatement.nonVersioned} />}
          </div>
        </li>
      </ol>
      <CodeSnippet code={renderServiceAccountDetails('aws')} label={'Grafana principal ARN'} />
    </>
  );
};
