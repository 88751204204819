import React from 'react';
import { cx } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { useCellDetails } from '../../../hooks';
import { getCommonStyles } from '../../../utils/common-styles';
import { CodeSnippet } from '../../CodeSnippet';

const getStyles = (theme: GrafanaTheme2) => {
  const commonStyles = getCommonStyles(theme);
  return {
    list: cx(commonStyles.list),
  };
};

export const InstructionsAzure = () => {
  const { data, isLoading, renderServiceAccountDetails } = useCellDetails();
  const styles = useStyles2(getStyles);

  const oauthAuthorizeCode = `https://login.microsoftonline.com/{{ YOUR_AZURE_TENANT_ID }}/oauth2/authorize?client_id=${
    data?.azure.application_id || '{{ GRAFANA_APPLICATION_ID }}'
  }&response_type=code&redirect_uri=https://grafana.com`;

  if (isLoading) {
    return <span data-testid={'loading-placeholder'} />;
  }

  return (
    <>
      <h3>Azure Blob Storage - Instructions</h3>
      <ol className={styles.list}>
        <li>Create a new storage account with a container for block blobs.</li>
        <li>
          Authorize the Cloud Logs Export service by opening the following URL that initializes an OAuth flow.
          <CodeSnippet code={oauthAuthorizeCode} label={'Example'} />
        </li>
        <li>Grant the Cloud Logs Export service access to the Azure Blob Storage using a role assignment.</li>
      </ol>
      <CodeSnippet code={renderServiceAccountDetails('azure')} label={'Grafana application id'} />
    </>
  );
};
