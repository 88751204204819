import React, { createContext, useMemo, useState } from 'react';
import { debounce as _debounce, some as _some } from 'lodash';

import { getApiEndpoints } from '../api';
import { Api } from '../types/api';
import { AppConfigProps } from '../types/app-config';

type LogExportContextType = {
  api: Api;
  shouldShowLoadingSpinner: boolean;
  setQueryKeyLoading: (queryKeys: string[], isLoading: boolean) => void;
};
export const LogExportContext = createContext<LogExportContextType>({} as LogExportContextType);

type Props = {
  appConfigProps: AppConfigProps;
  children: React.ReactNode;
};
export const LogExportContextProvider = ({
  appConfigProps: {
    plugin: { meta },
  },
  children,
}: Props) => {
  const api = useMemo(() => {
    return getApiEndpoints(meta.id);
  }, [meta.id]);

  const [loadingQueue, setLoadingQueue] = useState<{ [key: string]: boolean }>({});

  const [shouldShowLoadingSpinner, setShouldShowLoadingSpinner] = useState(false);

  // Debounce showing the loading spinner to prevent a quick flash if we will just remove it right away
  const debouncedSetShowLoadingSpinner = useMemo(() => {
    return _debounce((shouldShow: boolean) => {
      setShouldShowLoadingSpinner(shouldShow);
    }, 150);
  }, []);

  const setQueryKeyLoading = (queryKeys: string[], isLoading: boolean) => {
    const queue = { ...loadingQueue };
    queue[queryKeys.join('_')] = isLoading;
    setLoadingQueue(queue);
    debouncedSetShowLoadingSpinner(_some(queue, (v, _) => v));
  };

  return (
    <LogExportContext.Provider value={{ api, shouldShowLoadingSpinner, setQueryKeyLoading }}>
      {children}
    </LogExportContext.Provider>
  );
};
