import { AppPlugin } from '@grafana/data';

import { AppConfig } from './components/AppConfig';

export const plugin = new AppPlugin<{}>().addConfigPage({
  title: 'Configuration',
  icon: 'cog',
  // @ts-ignore - Would expect a Class component, however works absolutely fine with a functional one
  // Implementation: https://github.com/grafana/grafana/blob/fd44c01675e54973370969dfb9e78f173aff7910/public/app/features/plugins/PluginPage.tsx#L157
  body: AppConfig,
  id: 'configuration',
});
