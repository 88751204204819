import { Api } from '../types/api';

export const getApiEndpoints = (pluginId: string): Api => {
  return {
    v1: {
      cellDetails: `/api/plugins/${pluginId}/resources/v1/cell-details`,
      settings: `/api/plugins/${pluginId}/resources/v1/settings`,
      tenantConfigs: `/api/plugins/${pluginId}/resources/v1/tenant-configs`,
      testConnectivity: `/api/plugins/${pluginId}/resources/v1/test-connectivity`,
    },
  };
};

export * from './request';
