import React from 'react';

import { Button } from '@grafana/ui';

import useCopyToClipboard from '../../hooks';

type Props = {
  buttonVariant?: 'primary' | 'secondary' | undefined;
  className?: string;
  errorLabel?: string;
  label?: string;
  size?: any;
  // The text to display after a successful copy-to-clipboard action
  successLabel?: string | boolean;
  textToCopy: string;
};

export const CopyToClipboardButton = ({
  buttonVariant = 'secondary',
  className,
  label,
  size = 'md',
  successLabel = 'Copied to clipboard',
  textToCopy,
}: Props) => {
  const [isCopied, doCopy] = useCopyToClipboard(textToCopy);

  const shouldShowSuccessLabel = isCopied && successLabel;
  const buttonLabel = shouldShowSuccessLabel ? successLabel : label;
  const buttonIcon = shouldShowSuccessLabel ? 'check' : 'copy';

  return (
    <Button onClick={doCopy} className={className} fill={'text'} variant={buttonVariant} icon={buttonIcon} size={size}>
      {buttonLabel}
    </Button>
  );
};
