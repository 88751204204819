import React, { useEffect, useMemo, useState } from 'react';
import { css, cx } from '@emotion/css';
import { map as _map, trim as _trim } from 'lodash';

import { GrafanaTheme2, SelectableValue } from '@grafana/data';
import {
  Alert,
  Badge,
  Button,
  CollapsableSection,
  Field,
  FieldValidationMessage,
  Input,
  RadioButtonGroup,
  Select,
  Tooltip,
  useStyles2,
} from '@grafana/ui';

import { useInitTestConnectivity, useSaveConfigMutation, useTestConnectivityStatus } from '../../../hooks';
import { CloudProvider, FieldKey, FieldState, TenantConfig } from '../../../types/logs-export-config';
import {
  AWS_REGION_OPTIONS,
  BUCKET_CONNECTION_SUCCESSFUL,
  CLOUD_PROVIDER_TRANSLATION,
  MAX_INPUT_LENGTH,
  MAX_INPUT_LENGTH_ERROR_MESSAGE,
  REQUIRED_FIELD_ERROR_MESSAGE,
  UNEXPECTED_ERROR,
} from '../../../utils/constants';

import { InstructionsAws } from './InstructionsAws';
import { InstructionsAzure } from './InstructionsAzure';
import { InstructionsGcp } from './InstructionsGcp';

const getLogsExportConfigStyles = (theme: GrafanaTheme2) => {
  return {
    collapsableSectionWrapper: css`
      flex-direction: row;
      justify-content: flex-start;
      grid-gap: ${theme.spacing(2)};
    `,
    collapsableSectionContent: css`
      margin-left: ${theme.spacing(4)};
    `,
    disabledSection: css`
      color: ${theme.colors.text.disabled};
    `,
    field: css`
      width: 75%;
    `,
    actionWrapper: css`
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: ${theme.spacing(3)};
    `,
  };
};

const initialFieldState: FieldState = {
  bucket: { value: '', invalid: false },
  container: { value: '', invalid: false },
  region: { value: '', invalid: false },
  storage_account: { value: '', invalid: false },
  tenant_id: { value: '', invalid: false },
};

const providerOptions: SelectableValue[] = _map(CLOUD_PROVIDER_TRANSLATION, (label, value) => ({
  label,
  value,
}));

export const Form = () => {
  const [selectedCloudProvider, setSelectedCloudProvider] = useState<CloudProvider | undefined>();
  const [fields, setFields] = useState<FieldState>(initialFieldState);
  const [isSelectCloudProviderOpen, setIsSelectCloudProviderOpen] = useState(true);
  const [isBucketDetailsOpen, setIsBucketDetailsOpen] = useState(false);
  const [testConnectionStarted, setTestConnectionStarted] = useState(false);
  const [testConnectionError, setTestConnectionError] = useState<string>('');

  const styles = useStyles2(getLogsExportConfigStyles);

  const instructions = useMemo<{ [key: string]: JSX.Element }>(() => {
    return {
      aws: <InstructionsAws />,
      azure: <InstructionsAzure />,
      gcp: <InstructionsGcp />,
    };
  }, []);

  const {
    data: initTestConnectionData,
    isError: isInitTestConnectionError,
    error: initTestConnectionError,
    mutateAsync: initTestConnectionAsync,
    reset: initTestConnectionReset,
  } = useInitTestConnectivity();

  const {
    data: testConnectionStatus,
    isError: isTestConnectionStatusError,
    error: testConnectionStatusError,
  } = useTestConnectivityStatus(initTestConnectionData?.request_id);

  const { isError: isSaveConfigError, error: saveConfigError, mutateAsync: saveConfigAsync } = useSaveConfigMutation();

  useEffect(() => {
    if (selectedCloudProvider) {
      // If changing selectedCloudProvider reset the fields.
      setFields({ ...initialFieldState });
      // resets the testConnection mutation on provider change to remove any statuses or data from previous test
      initTestConnectionReset();
      setTestConnectionStarted(false);
      setIsBucketDetailsOpen(true);
    }
  }, [selectedCloudProvider, initTestConnectionReset]);

  useEffect(() => {
    if (isInitTestConnectionError || isTestConnectionStatusError) {
      setTestConnectionError(
        initTestConnectionError?.data.message || testConnectionStatusError?.data.message || UNEXPECTED_ERROR
      );
    } else if (testConnectionStatus?.status === 'failed') {
      setTestConnectionError(testConnectionStatus.msg || UNEXPECTED_ERROR);
    }
  }, [
    initTestConnectionError,
    isInitTestConnectionError,
    isTestConnectionStatusError,
    testConnectionStatusError,
    testConnectionStatus,
  ]);

  const cloudProviderToRequiredFields: { [key in CloudProvider]: FieldKey[] } = {
    aws: ['bucket', 'region'],
    azure: ['container', 'storage_account', 'tenant_id'],
    gcp: ['bucket'],
  };

  const buildTenantConfig = (): TenantConfig => {
    if (selectedCloudProvider) {
      const providerData: { [key in FieldKey]: string } = {} as { [key in FieldKey]: string };
      cloudProviderToRequiredFields[selectedCloudProvider].forEach((key) => {
        providerData[key] = _trim(fields[key].value);
      });
      return { enabled: true, provider: selectedCloudProvider, [selectedCloudProvider]: providerData };
    }
    throw new Error('No provider selected');
  };

  const updateCloudProvider = (newCloudProvider: CloudProvider) => {
    setSelectedCloudProvider(newCloudProvider);
  };

  const updateFieldValue = (key: FieldKey, value: string) => {
    const updated: FieldState = {
      ...fields,
    };
    updated[key] = {
      ...updated[key],
      value,
    };

    initTestConnectionReset();
    setTestConnectionStarted(false);
    setFields(updated);
  };

  // Fields can't be empty when testing or submitting
  const validateFields = (): boolean => {
    let invalidCount = 0;
    if (selectedCloudProvider) {
      const updated: FieldState = { ...fields };

      cloudProviderToRequiredFields[selectedCloudProvider].forEach((fieldKey) => {
        const { value } = updated[fieldKey];

        const trimmedValue = _trim(value);

        const isEmpty = !trimmedValue.length;
        const isTooLong = trimmedValue.length > MAX_INPUT_LENGTH;

        const invalid = isEmpty || isTooLong;

        invalidCount += invalid ? 1 : 0;

        updated[fieldKey] = {
          value,
          invalid,
          error_message: invalid
            ? isTooLong
              ? MAX_INPUT_LENGTH_ERROR_MESSAGE
              : REQUIRED_FIELD_ERROR_MESSAGE
            : undefined,
        };
      });
      setFields(updated);
      return invalidCount === 0;
    }

    // if no provider is selected we can't validate the fields
    return false;
  };

  const testConnectionOnClick = async () => {
    try {
      if (validateFields()) {
        await setTestConnectionError('');
        await setTestConnectionStarted(true);
        const { enabled, ...payload } = buildTenantConfig();
        await initTestConnectionAsync(payload);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const saveConfigOnClick = async () => {
    try {
      await saveConfigAsync(buildTenantConfig());
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <CollapsableSection
        className={styles.collapsableSectionWrapper}
        contentClassName={styles.collapsableSectionContent}
        isOpen={isSelectCloudProviderOpen}
        onToggle={(isOpen: boolean) => setIsSelectCloudProviderOpen(isOpen)}
        label={'Select target cloud provider'}
      >
        <RadioButtonGroup options={providerOptions} onChange={updateCloudProvider} value={selectedCloudProvider} />
      </CollapsableSection>
      <hr />
      <CollapsableSection
        className={styles.collapsableSectionWrapper}
        contentClassName={styles.collapsableSectionContent}
        label={'Create object storage'}
        isOpen={true}
      >
        {selectedCloudProvider && instructions[selectedCloudProvider]}
      </CollapsableSection>
      <hr />
      <CollapsableSection
        key={`bucket-details-section-${isBucketDetailsOpen}`}
        className={cx(styles.collapsableSectionWrapper, !selectedCloudProvider && styles.disabledSection)}
        contentClassName={styles.collapsableSectionContent}
        label={'Add your bucket details'}
        isOpen={isBucketDetailsOpen}
        onToggle={(isOpen: boolean) => setIsBucketDetailsOpen(isOpen)}
      >
        {selectedCloudProvider && (
          <div>
            {selectedCloudProvider === 'azure' && (
              <>
                <Field className={styles.field} label={'Azure tenant id'}>
                  <>
                    <Input
                      id={'azure-tenant-id'}
                      placeholder={'Enter the Azure tenant id...'}
                      value={fields.tenant_id.value}
                      invalid={fields.tenant_id.invalid}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        updateFieldValue('tenant_id', event.currentTarget.value);
                      }}
                    />
                    {fields.tenant_id.invalid && (
                      <FieldValidationMessage>{fields.tenant_id.error_message}</FieldValidationMessage>
                    )}
                  </>
                </Field>
                <Field className={styles.field} label={'Storage account name'}>
                  <>
                    <Input
                      id={'azure-storage-account-name'}
                      placeholder={'Enter the Azure Storage Account name...'}
                      value={fields.storage_account.value}
                      invalid={fields.storage_account.invalid}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        updateFieldValue('storage_account', event.currentTarget.value)
                      }
                    />
                    {fields.storage_account.invalid && (
                      <FieldValidationMessage>{fields.storage_account.error_message}</FieldValidationMessage>
                    )}
                  </>
                </Field>
                <Field className={styles.field} label={'Container name'}>
                  <>
                    <Input
                      id={'bucket-name'}
                      placeholder={`Enter the container name...`}
                      value={fields.container.value}
                      invalid={fields.container.invalid}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        updateFieldValue('container', event.currentTarget.value)
                      }
                    />
                    {fields.container.invalid && (
                      <FieldValidationMessage>{fields.container.error_message}</FieldValidationMessage>
                    )}
                  </>
                </Field>
              </>
            )}
            {selectedCloudProvider !== 'azure' && (
              <Field className={styles.field} label={'Bucket name'}>
                <>
                  <Input
                    id={'bucket-name'}
                    data-testid={'bucket-name'}
                    placeholder={`Enter the bucket name...`}
                    value={fields.bucket.value}
                    invalid={fields.bucket.invalid}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      updateFieldValue('bucket', event.currentTarget.value)
                    }
                  />
                  {fields.bucket.invalid && (
                    <FieldValidationMessage>{fields.bucket.error_message}</FieldValidationMessage>
                  )}
                </>
              </Field>
            )}

            {selectedCloudProvider === 'aws' && (
              <Field className={styles.field} label={'Bucket region'}>
                <>
                  <Select
                    options={AWS_REGION_OPTIONS}
                    value={fields.region.value}
                    invalid={fields.region.invalid}
                    placeholder={'Choose a region...'}
                    onChange={(option) => option.value && updateFieldValue('region', option.value)}
                  />
                  {fields.region.invalid && (
                    <FieldValidationMessage>{fields.region.error_message}</FieldValidationMessage>
                  )}
                </>
              </Field>
            )}
            {testConnectionStarted && testConnectionError && (
              <Alert title={'Test connection error'}>{testConnectionError}</Alert>
            )}
            {isSaveConfigError && (
              <Alert title={saveConfigError?.statusText || 'Error'}>
                {saveConfigError.data.message || UNEXPECTED_ERROR}
              </Alert>
            )}
            <div className={styles.actionWrapper}>
              {testConnectionStarted && testConnectionStatus?.status === 'success' && (
                <Badge text={BUCKET_CONNECTION_SUCCESSFUL} color={'green'} icon={'check'} />
              )}
              <Tooltip
                content={
                  'Clicking Test will ensure the Cloud Logs Export service has appropriate permissions to write data to your object storage.'
                }
                placement={'bottom-start'}
              >
                <Button variant={'secondary'} onClick={testConnectionOnClick}>
                  Test
                </Button>
              </Tooltip>
              <Tooltip
                content={'Clicking Submit will turn on the Cloud Logs Export service for this logs instance.'}
                placement={'bottom-start'}
              >
                <Button
                  variant={'primary'}
                  onClick={saveConfigOnClick}
                  disabled={!(initTestConnectionData?.request_id && testConnectionStatus?.status === 'success')}
                >
                  Submit
                </Button>
              </Tooltip>
            </div>
          </div>
        )}
      </CollapsableSection>
    </>
  );
};
