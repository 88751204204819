import React from 'react';
import { cx } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { useCellDetails } from '../../../hooks';
import { getCommonStyles } from '../../../utils/common-styles';
import { CodeSnippet } from '../../CodeSnippet';

const getStyles = (theme: GrafanaTheme2) => {
  const commonStyles = getCommonStyles(theme);
  return {
    list: cx(commonStyles.list),
  };
};

export const InstructionsGcp = () => {
  const styles = useStyles2(getStyles);
  const { renderServiceAccountDetails, isLoading } = useCellDetails();

  if (isLoading) {
    return <span data-testid={'loading-placeholder'} />;
  }

  return (
    <>
      <h3>Google Cloud Storage (GCS) - Instructions</h3>
      <ol className={styles.list}>
        <li>
          Create a GCS bucket; this will be where your logs are exported. The bucket should be created with uniform
          bucket-level access.
        </li>
        <li>
          Allow the Log Export feature access to your bucket by creating and adding a policy binding for the Logs Export
          service account.
        </li>
      </ol>
      <CodeSnippet code={renderServiceAccountDetails('gcp')} label={'Grafana service account'} />
    </>
  );
};
