import { SelectableValue } from '@grafana/data';

import { CloudProvider } from '../types/logs-export-config';

export const AWS_REGION_OPTIONS: SelectableValue[] = [
  { value: 'us-east-2', description: 'us-east-2', label: 'US East (Ohio)' },
  { value: 'us-east-1', description: 'us-east-1', label: 'US East (N. Virginia)' },
  { value: 'us-west-1', description: 'us-west-1', label: 'US West (N. California)' },
  { value: 'us-west-2', description: 'us-west-2', label: 'US West (Oregon)' },
  { value: 'af-south-1', description: 'af-south-1', label: 'Africa (Cape Town)' },
  { value: 'ap-east-1', description: 'ap-east-1', label: 'Asia Pacific (Hong Kong)' },
  { value: 'ap-south-2', description: 'ap-south-2', label: 'Asia Pacific (Hydrerabad)' },
  { value: 'ap-southeast-3', description: 'ap-southeast-3', label: 'Asia Pacific (Jakarta)' },
  { value: 'ap-south-1', description: 'ap-south-1', label: 'Asia Pacific (Mumbai)' },
  { value: 'ap-northeast-3', description: 'ap-northeast-3', label: 'Asia Pacific (Osaka)' },
  { value: 'ap-northeast-2', description: 'ap-northeast-2', label: 'Asia Pacific (Seoul)' },
  { value: 'ap-southeast-1', description: 'ap-southeast-1', label: 'Asia Pacific (Singapore)' },
  { value: 'ap-southeast-2', description: 'ap-southeast-2', label: 'Asia Pacific (Sydney)' },
  { value: 'ap-northeast-1', description: 'ap-northeast-1', label: 'Asia Pacific (Tokyo)' },
  { value: 'ca-central-1', description: 'ca-central-1', label: 'Canada (Central)' },
  { value: 'eu-central-1', description: 'eu-central-1', label: 'Europe (Frankfurt)' },
  { value: 'eu-west-1', description: 'eu-west-1', label: 'Europe (Ireland)' },
  { value: 'eu-west-2', description: 'eu-west-2', label: 'Europe (London)' },
  { value: 'eu-south-1', description: 'eu-south-1', label: 'Europe (Milan)' },
  { value: 'eu-west-3', description: 'eu-west-3', label: 'Europe (Paris)' },
  { value: 'eu-south-2', description: 'eu-south-2', label: 'Europe (Spain)' },
  { value: 'eu-north-1', description: 'eu-north-1', label: 'Europe (Stockholm)' },
  { value: 'eu-central-2', description: 'eu-central-2', label: 'Europe (Zurich)' },
  { value: 'me-south-1', description: 'me-south-1', label: 'Middle East (Bahrain)' },
  { value: 'me-central-1', description: 'me-central-1', label: 'Middle East (UAE)' },
  { value: 'sa-east-1', description: 'sa-east-1', label: 'South America (São Paulo)' },
  { value: 'us-gov-east-1', description: 'us-gov-east-1', label: 'AWS GovCloud (US-East)' },
  { value: 'us-gov-west-1', description: 'us-gov-west-1', label: 'AWS GovCloud (US-West)' },
];

export const CLOUD_PROVIDER_TRANSLATION: { [key in CloudProvider]: string } = {
  aws: 'Amazon S3',
  azure: 'Azure Blob Storage',
  gcp: 'Google Cloud Storage',
};

export const UNEXPECTED_ERROR = 'An unexpected error occurred';
export const MAX_INPUT_LENGTH = 512;
export const MAX_INPUT_LENGTH_ERROR_MESSAGE = `Field value greater than max number of characters [${MAX_INPUT_LENGTH}]`;
export const REQUIRED_FIELD_ERROR_MESSAGE = 'Field is required';

export const BUCKET_CONNECTION_SUCCESSFUL = 'Bucket connection was successful';
