import React from 'react';

export const DashboardLink = () => {
  return (
    <>
      Once the exporter is enabled, you can monitor its status on the dashboard{' '}
      <a className={'external-link'} href={'d/4_D6mSh4z/cloud-logs-export-insights'}>
        Cloud Logs Export Insights
      </a>
      .
    </>
  );
};
