import React from 'react';
import { css, cx } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { CopyToClipboardButton } from './CopyToClipboardButton';

const getStyles = (theme: GrafanaTheme2) => {
  return {
    wrapper: css`
      position: relative;
      color: ${theme.colors.text.secondary};
      margin-top: ${theme.spacing(2)};
      width: 75%;
    `,
    codeWrapper: css`
      display: flex;
      justify-content: space-between;
      gap: ${theme.spacing(1)};
      background-color: ${theme.colors.background.canvas};
      border: 1px solid ${theme.colors.border.medium};
      border-radius: ${theme.shape.borderRadius(2)};
    `,
    codeBlock: css`
      margin: 0;
      background-color: transparent;
      border: none;
    `,
    copyButton: css`
      align-self: flex-start;
    `,
    label: css`
      margin-bottom: ${theme.spacing(0.5)};
    `,
  };
};

type Props = {
  /** Additional className for styling. */
  className?: string;
  /** The piece of code that is going to be displayed and can be copied to clipboard. */
  code: string;
  /** label that is displayed above the code snippet */
  label?: string;
};

export const CodeSnippet = ({ className, code, label }: Props) => {
  const styles = useStyles2(getStyles);

  return (
    <div className={cx(styles.wrapper, className)}>
      {label && <div className={styles.label}>{label}</div>}
      <div className={styles.codeWrapper}>
        <pre className={styles.codeBlock}>{code}</pre>
        <div className={styles.copyButton}>
          <CopyToClipboardButton textToCopy={code} />
        </div>
      </div>
    </div>
  );
};
