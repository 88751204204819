import React from 'react';

import { DashboardLink } from '../DashboardLink';
import { DocumentationLink } from '../DocumentationLink';

type Props = {
  title: string;
};
export const PageHeader = ({ title }: Props) => {
  return (
    <>
      <h1>{title}</h1>
      <DocumentationLink />
      <DashboardLink />
      <hr />
    </>
  );
};
