import React from 'react';
import { css } from '@emotion/css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { LogExportContextProvider } from '../../context/log-export-context';
import { AppConfigProps } from '../../types/app-config';
import { LoadingSpinnerOverlay } from '../LoadingSpinnerOverlay';
import { SelfServeWrapper } from '../SelfServeWrapper';

import { AppConfigContent } from './AppConfigContent';

if (process.env.NODE_ENV === 'development' && process.env.ENABLE_MSW) {
  const { worker } = require('../../mocks/browser');
  worker.start({
    serviceWorker: {
      url: '/public/msw/mockServiceWorker.js',
      options: {
        scope: '/plugins/',
      },
    },
    onUnhandledRequest: 'bypass',
  });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
      retryOnMount: false,
    },
  },
});

const getStyles = (theme: GrafanaTheme2) => {
  return {
    wrapper: css`
      position: relative;
    `,
  };
};

export const AppConfig = (appConfigProps: AppConfigProps) => {
  const styles = useStyles2(getStyles);
  return (
    <div className={styles.wrapper}>
      <QueryClientProvider client={queryClient}>
        <LogExportContextProvider appConfigProps={appConfigProps}>
          <SelfServeWrapper>
            <AppConfigContent />
          </SelfServeWrapper>
          <LoadingSpinnerOverlay />
        </LogExportContextProvider>
      </QueryClientProvider>
    </div>
  );
};
