import React from 'react';

import { Alert } from '@grafana/ui';

import { useTenantConfig } from '../../hooks';
import * as LogsExportConfig from '../LogsExportConfig';
import { PageHeader } from '../PageHeader';

export const AppConfigContent = () => {
  const {
    data: tenantConfigData,
    isError: isTenantConfigError,
    error: tenantConfigError,
    isLoading: isTenantConfigLoading,
  } = useTenantConfig();

  if (isTenantConfigLoading) {
    return <span data-testid={'loading-placeholder'} />;
  }

  if (isTenantConfigError) {
    if (tenantConfigError.status === 404) {
      return (
        <>
          <PageHeader title={'Create a logs export bucket'} />
          <LogsExportConfig.Form />
        </>
      );
    } else {
      return (
        <Alert title={'Something went wrong'} severity={'error'}>
          {tenantConfigError.statusText}
        </Alert>
      );
    }
  }

  if (tenantConfigData) {
    return (
      <div className="gf-form-group">
        <PageHeader title={'Cloud logs export details'} />
        <LogsExportConfig.Summary data={tenantConfigData} />
      </div>
    );
  } else {
    return (
      <Alert title={'Something went wrong'} severity={'error'}>
        Something happened. Please try again later.
      </Alert>
    );
  }
};
