import { BackendSrvRequest, getBackendSrv } from '@grafana/runtime';

const defaultOptions: Partial<BackendSrvRequest> = {
  showErrorAlert: false,
  showSuccessAlert: false,
};
export const get = <T>(
  url: string,
  params: Record<string, any> = {},
  options: Partial<BackendSrvRequest> = defaultOptions
): Promise<T> => {
  return getBackendSrv().get<T>(url, params, undefined, options);
};

export const put = <V, T>(url: string, data: V, options: Partial<BackendSrvRequest> = defaultOptions): Promise<T> => {
  return getBackendSrv().put<T>(url, data, options);
};

export const post = <T>(
  url: string,
  data: unknown,
  options: Partial<BackendSrvRequest> = defaultOptions
): Promise<T> => {
  return getBackendSrv().post<T>(url, data, options);
};
